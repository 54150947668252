<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog payment-order"
  >
    <v-card>
      <v-card-title class="card-title">
        <span class="headline">Cuotas</span>
        <v-spacer></v-spacer>
        <CustomButtonAction :btnCancel="true" @onChangeCancel="btnBack()" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <div class="info-total">
            <div class="content-grid">
              <div class="row-grid">
                <div class="colum">Cuotas:</div>
                <div class="colum">
                  {{
                    selectOrden.weeks.length > 0
                      ? selectOrden.weeks[selectOrden.weeks.length - 1].week
                      : null
                  }}
                </div>
              </div>
              <div class="row-grid">
                <div class="colum">Total acumulado:</div>
                <div :class="`colum ${erroTotal}`">
                  {{ getAccumulated(listWeek) }} $
                </div>
              </div>
              <div class="row-grid">
                <div class="colum">Falta por cancelar:</div>
                <div :class="`colum ${errorPayment}`">
                  <div>{{ getTotalCancel(listWeek) }} $</div>
                </div>
              </div>
            </div>
          </div>

          <v-row v-for="(item, i) in listWeek" :key="i">
            <v-col cols="12">
              <v-text-field
                :label="item.week"
                outlined
                color="second"
                v-model="item.payment"
                dense
                prepend-inner-icon="mdi-currency-usd"
                class="input"
                :rules="paymentRules"
                :disabled="paymentDisabled(item.payment, listWeek)"
                @keypress="keyUpPayment(i, $event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container class="footer">
          <v-btn
            v-if="accumulatedTotal"
            block
            color="success"
            class="white--text save"
            @click="btnSave()"
            :loading="btnLoader"
          >
            Procesar pedido
          </v-btn>
          <v-btn
            block
            v-if="!accumulatedTotal"
            color="second"
            class="white--text"
            @click="btnUdpate()"
            :loading="btnLoader"
          >
            Actualizar
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";

export default {
  name: "PaymentOrder",
  data: () => ({
    viewModal: false,
    listWeek: [],
    selectWeek: [],
    paymentRules: [],
    cancelTotal: 0,
    erroTotal: "",
    errorPayment: "",
    accumulatedTotal: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    btnLoader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
      this.getListWeek();
    },
  },
  computed: {
    ...mapState(["selectOrden"]),
  },
  components: {
    CustomButtonAction,
  },
  methods: {
    btnBack() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
    },
    btnUdpate() {
      const data = {
        weeks: this.listWeek,
        modal: false,
      };
      this.$emit("onChange", data);
    },
    btnSave() {
      const data = {
        weeks: this.listWeek,
        check: 2,
        new: true,
        modal: true,
      };
      this.$emit("onChange", data);
    },
    getListWeek() {
      const list = [];
      this.selectOrden.weeks.map((item, i) => {
        list.push({
          week: `Semana ${i + 1}`,
          payment: item.payment,
        });
      });
      this.listWeek = list;
      this.paymentRules = [
        (v) => {
          if (parseFloat(v) <= parseFloat(this.selectOrden.total)) return true;
          return `La cuota debe ser menor o igual que el valor total ${this.selectOrden.total}$`;
        },
      ];
    },
    getAccumulated(weeks) {
      let total = 0;
      weeks.map((item) => {
        if (item.payment) {
          total = total + parseFloat(item.payment);
        }
      });

      this.erroTotal =
        total > parseFloat(this.selectOrden.total) ? "errorTotal" : "";
      this.accumulatedTotal =
        total === parseFloat(this.selectOrden.total) ? true : false;

      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(total);
      return format;
    },
    getTotalCancel(weeks) {
      let total = 0;
      weeks.map((item) => {
        if (item.payment) {
          total = total + parseFloat(item.payment);
        }
      });

      const result = this.selectOrden.total - total;
      this.errorPayment = result < 0 ? "errorTotal" : "";

      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(result);
      return format;
    },
    keyUpPayment(index, event) {
      if (
        event.which != 8 &&
        isNaN(String.fromCharCode(event.which)) &&
        event.key != "." &&
        event.key != "," &&
        event.key != "ArrowLeft" &&
        event.key != "ArrowRight" &&
        event.key != "ArrowDown" &&
        event.key != "ArrowUp"
      ) {
        event.preventDefault();
        let format = this.listWeek[index].payment;
        format = format.replace(",", ".");
        this.listWeek[index].payment = format;
      }
    },
    paymentDisabled(value, weeks) {
      let total = 0;
      weeks.map((item) => {
        if (item.payment) {
          total = total + parseFloat(item.payment);
        }
      });

      if (value === 0 && total === parseFloat(this.selectOrden.total)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.payment-order {
  .row {
    margin-bottom: -20px;
  }

  .row + .row {
    margin-bottom: -20px;
    margin-top: 0px;
  }

  .input input {
    // text-align: right;
  }

  .errorTotal {
    color: var(--color-danger) !important;
  }

  .info-total {
    margin-bottom: 20px;
    border: solid 1px #ccc;
    border-radius: 6px;

    .content-grid {
      padding: 10px;
      display: grid;
      row-gap: 10px;
      grid-template-columns: 1fr;

      .row-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .colum {
          font-size: 13px;
          color: var(--color-second);
          font-weight: 500;
        }

        .colum:nth-child(2) {
          text-align: right;
          font-size: 13px;
        }
      }
    }
  }

  .footer {
    padding: 0px !important;

    .save {
      margin-bottom: 10px;
    }
  }
}
</style>
