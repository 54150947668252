<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog adjunt-order"
  >
    <v-card>
      <v-card-title class="card-title">
        <span class="headline">Adjuntar imagen</span>
        <v-spacer></v-spacer>
        <CustomButtonAction :btnCancel="true" @onChangeCancel="btnBack()" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <input
          id="attached-update"
          type="file"
          accept="image/jpeg,image/png,.jpeg,.jpg,.png"
          style="display: none"
          @change="processWebImage($event)"
        />
        <div class="content-grid">
          <div class="col-img" v-for="(image, i) in attached" :key="i">
            <img :src="image | imageDefault" />
            <div class="remove" @click="removeImg(i)">
              <v-icon>mdi-close</v-icon>
            </div>
          </div>
          <label class="col-img" for="attached-update">
            <div class="btn-img">
              <v-icon>mdi-plus</v-icon>
            </div>
          </label>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          block
          color="second"
          class="white--text"
          @click="btnSave()"
          :loading="btnLoader"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
    <CustomCrop
      :dialog.sync="modalCrop"
      :imgSrc.sync="photo"
      @onChange="onChangeCrop($event)"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomCrop from "../../../../shared/components/custom-crop/CustomCrop.vue";

export default {
  name: "AdjuntOrder",
  data: () => ({
    viewModal: false,
    modalCrop: false,
    photo: "",
    attached: [],
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    btnLoader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
      this.getListAttached();
    },
  },
  computed: {
    ...mapState(["selectOrden"]),
  },
  components: {
    CustomButtonAction,
    CustomCrop,
  },
  methods: {
    btnBack() {
      this.viewModal = false;
      this.attached = [];
      this.$emit("update:dialog", false);
    },
    processWebImage(event) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const image64 = readerEvent.target.result;
        this.modalCrop = true;
        this.photo = image64;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    onChangeCrop(event) {
      this.attached.unshift(event);
    },
    getListAttached() {
      this.attached = [];
      this.selectOrden.attached.map((element) => {
        this.attached.push(element);
      });
    },
    removeImg(index) {
      this.attached.splice(index, 1);
    },
    btnSave() {
      this.$emit("onChange", this.attached);
    },
  },
};
</script>

<style lang="scss">
.adjunt-order {
  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;

    .col-img {
      display: block;
      margin: auto;
      width: 100%;
      height: 100px;
      position: relative;

      img {
        border: solid 1px #ccc;
      }

      .remove {
        position: absolute;
        top: -5px;
        right: -5px;
        background: #eb445a;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        i {
          font-size: 16px !important;
          color: #fff;
        }
      }

      .btn-img {
        width: 110px;
        height: 110px;
        border: solid 2px #aaa;
        border-style: dashed;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        i {
          font-size: 25px !important;
          color: #aaa;
        }
      }
    }
  }
}
</style>
