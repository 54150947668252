<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog commnet-order"
  >
    <v-card>
      <v-card-title class="card-title">
        <span class="headline">Comentarios</span>
        <v-spacer></v-spacer>
        <CustomButtonAction :btnCancel="true" @onChangeCancel="btnBack()" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text id="comment">
        <v-container>
          <v-row v-for="(item, i) in selectOrden.comment" :key="i">
            <v-col cols="12">
              <v-card>
                <v-card-subtitle class="subtitle">
                  {{ item.createdAt | dateTime }}
                </v-card-subtitle>
                <v-card-title class="title">
                  {{ item.user }}
                </v-card-title>
                <v-card-text class="comment-m">
                  {{ item.message }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="comment-message">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="content-grid">
            <div class="message">
              <v-textarea
                outlined
                label="Agregar comentario"
                v-model="comment"
                color="second"
                :rules="requiredRules"
                rows="4"
                dense
              ></v-textarea>
            </div>
            <div class="btn">
              <v-btn
                color="second"
                class="white--text"
                @click="btnSend()"
                :loading="btnLoader"
              >
                Enviar
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import useValidate from "../../../../shared/validate/useValidate";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";

export default {
  name: "CommentOrder",
  data: () => ({
    viewModal: false,
    valid: false,
    comment: "",
    requiredRules: [useValidate["required"]],
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    btnLoader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.viewModal = val;
        setTimeout(() => {
          const scroll = document.getElementById("comment");
          const height = scroll.scrollHeight;
          scroll.scrollTop = height;
        }, 100);
      }
    },
  },
  computed: {
    ...mapState(["user", "selectOrden"]),
  },
  components: {
    CustomButtonAction,
  },
  created() {},
  methods: {
    btnBack() {
      this.viewModal = false;
      this.$refs.form.reset();
      this.$emit("update:dialog", false);
    },
    btnSend() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const comment = {
          _id: this.user._id,
          user: `${this.user.name} ${this.user.lastName}`,
          message: this.comment,
          createdAt: new Date(),
          typeUser: this.user.typeUser,
          new: true,
        };
        this.$emit("onChange", comment);
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style lang="scss">
.commnet-order {
  .v-card > .title {
    padding: 0px 16px !important;
    font-size: 14px !important;
    font-weight: 500;
    margin-top: -10px;
    color: var(--color-second);
  }

  .subtitle {
    padding: 10px 16px 1px;
    text-align: right;
    font-size: 12px !important;
    color: var(--color-second);
    font-weight: 300;
  }

  .container {
    padding: 2px;
  }

  .v-card > .comment-m {
    padding: 0px 16px 16px !important;
    font-size: 13px;
    color: var(--color-second);
    font-weight: 300;
  }

  .comment-message {
    .content-grid {
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 16px;

      .message {
        .v-input {
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
