<template>
  <div class="order-mobile">
    <v-tabs color="second" show-arrows @change="btnTab($event)" v-model="tab">
      <v-tabs-slider color="second"></v-tabs-slider>
      <v-tab>Cuotas</v-tab>
      <v-tab>En proceso</v-tab>
      <v-tab>Verificación</v-tab>
      <v-tab>Completado</v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-container>
      <v-row class="row-search">
        <v-col cols="12">
          <v-text-field
            color="second"
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar ..."
            solo
            dense
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="listOrders.length === 0">
        <v-col cols="12">
          <div class="message-error">No existen órdenes registradas</div>
        </v-col>
      </v-row>

      <v-row v-for="(item, i) in listFilter(listOrders)" :key="i">
        <v-col cols="12">
          <v-card>
            <v-list :class="item.id === selectOrder ? 'row-active' : ''">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title" v-if="item.code">
                    N° Pedido: {{ item.code }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="subtitle">
                    {{ item.createdAt | dateTime }}
                  </v-list-item-subtitle>

                  <table>
                    <tr>
                      <td class="col-td">
                        <v-icon>mdi-badge-account-horizontal-outline</v-icon>
                      </td>
                      <td class="col-td-2">{{ item.client.dni | unitMile }}</td>
                    </tr>
                    <tr>
                      <td class="col-td">
                        <v-icon>mdi-account-outline</v-icon>
                      </td>
                      <td class="col-td-2">
                        {{ item.client.name }} {{ item.client.lastName }}
                      </td>
                    </tr>
                  </table>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    color="second"
                    outlined
                    small
                    @click="btnDetail(item)"
                  >
                    <v-icon>mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <DetailOrder :dialog.sync="modal" @onChange="getRefresh()" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DetailOrder from "./detail.vue";
import useSearch from "../../../../shared/filters/useSearch";
import OrderServices from "../../../../core/services/order-services";
import OrderStorage from "../../../../core/storage/order-storage";

export default {
  name: "Order",
  data: () => ({
    search: "",
    modal: false,
    listOrders: [],
    tab: 0,
    selectOrder: null,
    query: {},
  }),
  components: {
    DetailOrder,
  },
  computed: {
    ...mapState(["orders"]),
    ...mapGetters(["getOrdersByStatus"]),
  },
  created() {
    this.listOrders = this.getOrdersByStatus(1);
    this.getListOrder();

    this.query = Object.assign({}, this.$route.query);
  },
  methods: {
    ...mapActions(["getListOrders", "setSelectOrdenItem"]),
    listFilter(data) {
      return useSearch({
        data: data,
        search: this.search,
        modulo: "order",
      });
    },
    btnDetail(data) {
      this.setSelectOrdenItem({ orden: data });
      this.modal = true;
      this.selectOrder = null;
    },
    getListOrder() {
      const { getByIdSeller } = OrderServices();
      const { orderAdd, orderClear } = OrderStorage();
      getByIdSeller().then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          orderClear();
          response.data.map((item) => {
            orderAdd(item);
          });
          this.getListOrders();
          this.filterSearchQuery();
        } else {
          if (response.code === 404) {
            orderClear();
            this.getListOrders();
            this.filterSearchQuery();
          }
        }
      });
    },
    filterSearchQuery() {
      if (this.query.order) {
        this.selectOrder = this.query.order;
        switch (parseInt(this.query.check)) {
          case 2:
            this.tab = 1;
            this.listOrders = this.getOrdersByStatus(2);
            break;
          case 3:
            this.tab = 2;
            this.listOrders = this.getOrdersByStatus(3);
            break;
          case 4:
            this.tab = 3;
            this.listOrders = this.getOrdersByStatus(4);
            break;
        }
      }
    },
    btnTab(event) {
      this.listOrders = this.getOrdersByStatus(event + 1);
    },
    getRefresh() {
      this.getListOrders();
      this.tab = 1;
      this.listOrders = this.getOrdersByStatus(2);
    },
  },
};
</script>

<style lang="scss">
.order-mobile {
  .row-search {
    margin-bottom: -35px;
  }

  .row + .row {
    margin-top: auto;
  }

  .title {
    font-size: 16px !important;
    word-break: normal;
    color: var(--color-second) !important;
    margin-top: 8px;
  }

  .subtitle {
    font-size: 12px;
    color: var(--color-second) !important;
    position: absolute;
    right: 10px;
    top: 0px;
  }

  .content-text {
    padding: 8px 16px 16px;
  }

  table {
    padding: 0px;
    width: 100%;
    margin-top: 8px;
  }

  .col-td {
    width: 36px;
    height: 20px;
    color: var(--color-second);

    i {
      font-size: 20px;
    }
  }

  .col-td-2 {
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 20px;
    color: var(--color-second);
  }

  .message-error {
    text-align: center;
    font-size: 18px;
    opacity: 0.4;
    margin-top: 10px;
  }
}
</style>
