<template>
  <div class="product">
    <v-dialog
      v-model="viewModal"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
      content-class="dialog-product"
    >
      <v-card>
        <v-card-title class="card-title">
          <CustomButtonAction :btnBack="true" @onChangeBack="btnBack()" />
          <v-spacer></v-spacer>
          <div class="type-product">
            <img
              src="img/home/logo-top-1.png"
              alt="logo-1"
              v-if="item.name == 'La-Phée'"
            />
            <img
              src="img/home/logo-top-2.png"
              alt="logo-2"
              v-if="item.name == 'Skin Up'"
            />
          </div>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                color="second"
                prepend-inner-icon="mdi-magnify"
                placeholder="Buscar ..."
                solo
                dense
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="content-grid">
            <div
              class="card-img"
              v-for="(item, i) in listFilter($store.state.products[item.name])"
              :key="i"
            >
              <img :src="item.photo | urlImage" :alt="item.name" />
              <div
                class="stock"
                :style="{ color: item.minStock >= item.stock ? 'red' : '' }"
              >
                <v-icon
                  :style="{ color: item.minStock >= item.stock ? 'red' : '' }"
                >
                  mdi-package-variant-closed
                </v-icon>
                {{ item.stock }} Disponibles
              </div>
              <div class="title">{{ item.name }}</div>
              <div class="price">$ {{ item.price }}</div>
              <div class="count">
                <div class="btn-minus">
                  <span @click="btnCount(item, i, 'remove')">
                    <v-icon>mdi-minus</v-icon>
                  </span>
                </div>
                <div class="number">
                  <input type="number" v-model="item.count" />
                </div>
                <div class="btn-plus">
                  <span @click="btnCount(item, i, 'add')">
                    <v-icon>mdi-plus</v-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductService from "../../../../core/services/product-services";
import ProductStorage from "../../../../core/storage/product-storage";
import useSearch from "../../../../shared/filters/useSearch";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";

export default {
  name: "ProductOrder",
  data: () => ({
    viewModal: false,
    listProduct: [],
    search: "",
  }),
  components: {
    CustomButtonAction,
  },
  computed: {
    ...mapState(["products"]),
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
    item: function (val) {
      if (val.id) {
        this.getProductList(val);
        this.countProduct();
      }
    },
  },
  methods: {
    ...mapActions(["setProduct", "getListProducts"]),
    btnBack() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:item", {});
      this.listProduct = [];
    },
    listFilter(data) {
      return useSearch({
        data: data,
        search: this.search,
        modulo: "product",
      });
    },
    getProductList(value) {
      const { getByIdMark } = ProductService();
      const { productAdd } = ProductStorage();
      getByIdMark(value._id).then((resp) => {
        const response = resp.data;
        if (response.code === 200) {
          const data = {
            id: this.item.id,
            name: this.item.name,
            data: response.data,
            createdAt: new Date(),
          };
          productAdd(data);
          this.getListProducts();
        }
      });
    },
    btnCount(item, index, type) {
      let stock = 0;
      let listProduct = this.products;
      item.count = item.count ? item.count : 0;

      if (!localStorage.getItem(`${item.id}_stock`)) {
        localStorage.setItem(`${item.id}_stock`, item.stock);
      }

      if (!localStorage.getItem(`${item.id}_count`)) {
        localStorage.setItem(`${item.id}_count`, item.count.toString());
      }
      switch (type) {
        case "add":
          if (item.stock !== 0) {
            item.count = item.count + 1;
            item.stock = item.stock - 1;
          }
          break;
        case "remove":
          item.count = item.count - 1;
          item.count = item.count <= 0 ? 0 : item.count;
          stock =
            parseInt(localStorage.getItem(`${item.id}_stock`)) +
            parseInt(localStorage.getItem(`${item.id}_count`));

          if (item.stock < stock) {
            item.stock = item.stock + 1;
          }
          break;
      }

      item.updateStock = item.stock;
      listProduct[this.item.name][index] = item;
      this.setProduct({
        products: listProduct[this.item.name],
        name: this.item.name,
      });

      const cart = this.$store.state.selectOrden.products;
      const newCart = [];
      const cartIndex = cart.findIndex((element) => element.id === item.id);
      if (cartIndex && cartIndex !== -1) {
        cart[cartIndex] = item;
        if (item.count === 0) {
          delete cart[cartIndex];
          cart.map((item) => {
            if (item) {
              newCart.push(item);
            }
          });
          this.$emit("onChange", newCart);
        } else {
          this.$emit("onChange", cart);
        }
      } else if (cartIndex === -1 && item.count !== 0) {
        cart.push(item);
        this.$emit("onChange", cart);
      }
    },
    countProduct() {
      const listProduct = this.$store.state.products;
      const productsAll = this.$store.state.products[this.item.name];
      const productSelect = this.$store.state.selectOrden.products;
      productsAll.map((product, index) => {
        const item = productSelect.filter(
          (element) => element.id === product.id
        )[0];
        if (item) {
          listProduct[this.item.name][index] = item;
          this.setProduct({
            products: listProduct[this.item.name],
            name: this.item.name,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.dialog-product {
  .v-card > .v-card__title {
    background: var(--color-white) !important;
    padding: 8px 16px 8px !important;
    color: var(--color-darkLight) !important;
  }

  .v-card__text {
    padding: 16px !important;
  }

  .type-product {
    img {
      width: 100px;
      display: flex;
      margin-right: 10px;
    }
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 40px;

    .card-img {
      img {
        width: 100%;
        border: solid 1px #ddd;
      }

      .title {
        color: var(--color-second);
        font-weight: 500;
        font-size: 12px;
      }

      .price {
        color: var(--color-second);
        font-weight: 500;
        font-size: 13px;
      }

      .stock {
        font-size: 10px;
        font-weight: 500;

        i {
          font-size: 14px;
          color: #a1a1a1;
        }
      }

      .count {
        display: grid;
        grid-template-columns: 30px 60px 30px;
        margin-top: 10px;

        .btn-minus {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            border-radius: 100%;
            background: var(--color-primary);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          i {
            color: var(--color-white);
            font-size: 16px;
          }
        }

        .number {
          position: relative;
          display: flex;
          justify-content: center;

          input {
            border: solid 1px var(--color-second);
            width: 50px;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            padding: 2px 8px;
          }
        }

        .btn-plus {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            border-radius: 100%;
            background: var(--color-primary);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          i {
            color: var(--color-white);
            font-size: 16px;
          }
        }
      }
    }
  }

  .btnFab {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}
</style>
