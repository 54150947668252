<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog cart-order"
  >
    <v-card>
      <v-card-title class="card-title">
        <span class="headline">Productos</span>
        <v-spacer></v-spacer>
        <CustomButtonAction :btnCancel="true" @onChangeCancel="btnBack()" />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row v-for="(item, i) in listCart" :key="i">
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <div class="content-grid">
                    <div class="img">
                      <img :src="item.photo | urlImage" :alt="item.name" />
                    </div>
                    <div class="content">
                      <div
                        class="close"
                        @click="deleteProduct(item)"
                        v-if="selectOrden.check !== 4"
                      >
                        <v-icon>mdi-close</v-icon>
                      </div>
                      <div class="title">{{ item.name }}</div>
                      <div class="grid-info">
                        <div class="price">$ {{ item.price }}</div>
                        <div class="count">
                          <div class="btn-minus">
                            <span
                              @click="btnCount(item, i, 'remove')"
                              :class="
                                selectOrden.check !== 4 ? '' : 'eventNone'
                              "
                            >
                              <v-icon>mdi-minus</v-icon>
                            </span>
                          </div>
                          <div class="number">
                            <input
                              type="number"
                              v-model="item.count"
                              :class="
                                selectOrden.check !== 4 ? '' : 'eventNone'
                              "
                            />
                          </div>
                          <div class="btn-plus">
                            <span
                              @click="btnCount(item, i, 'add')"
                              :class="
                                selectOrden.check !== 4 ? '' : 'eventNone'
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="footer">
        <v-container>
          <v-row>
            <v-col cols="12" style="padding: 12px 0px">
              <div class="grid-content">
                <div class="col1">Total:</div>
                <div class="col2">
                  <table>
                    <tr>
                      <td>
                        <div>{{ totalDolar() }} $</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>{{ totalBsFormat() }} Bs</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions v-if="selectOrden.check !== 4">
        <div class="btn-group">
          <v-btn
            block
            large
            color="second"
            class="white--text"
            @click="btnSelectMark()"
          >
            Agregar productos
          </v-btn>

          <v-btn
            block
            large
            color="second"
            class="white--text"
            @click="btnSave()"
            :loading="btnLoader"
          >
            Actualizar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-bottom-sheet v-model="sheet" inset>
      <v-sheet class="text-center">
        <div class="contentMark">
          <div
            v-for="(item, i) in listMark"
            :key="i"
            :class="'logo area-' + i"
            @click="btnProduct(item)"
          >
            <img :src="item.photo | urlImage" alt="logo-1" />
          </div>

          <div class="bar"></div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <ProductOrder
      :dialog.sync="modalProduct"
      :item.sync="dataMark"
      @onChange="changeCart($event)"
    />

    <CustomDialog
      :dialog.sync="deleteModal"
      :title="'Confirmar eliminación'"
      :message="'¿Estás seguro que quieres eliminar el producto?'"
      :maxWidth="'320'"
      @onChange="btnDeleteProduct()"
    ></CustomDialog>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductOrder from "./product.vue";
import MarkStorage from "../../../../core/storage/mark-storage";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "CartOrder",
  data: () => ({
    viewModal: false,
    listCart: [],
    total: 0,
    valueDolar: 0,
    valueBs: 0,
    sheet: false,
    listMark: [],
    modalProduct: false,
    dataMark: {},
    selectMark: {},
    itemDelete: {},
    deleteModal: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    btnLoader: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
      this.getListCart();
      this.calcTotal();
      this.getMarkStorage();
    },
  },
  computed: {
    ...mapState(["selectOrden"]),
  },
  components: {
    CustomButtonAction,
    ProductOrder,
    CustomDialog,
  },
  methods: {
    ...mapActions([
      "getSelectOrdenItemDefault",
      "clearProducts",
      "setSelectOrdenItem",
    ]),
    btnBack() {
      this.viewModal = false;
      this.getSelectOrdenItemDefault({ orden: this.selectOrden });
      // this.clearProducts({ name: this.selectMark.name });
      this.$emit("update:dialog", false);
    },
    btnSave() {
      const data = {
        products: this.listCart,
        total: this.valueDolar,
        totalBs: this.valueBs,
      };
      this.clearProducts({ name: this.selectMark.name });
      this.$emit("onChange", data);
    },
    getListCart() {
      this.listCart = [];
      this.selectOrden.products.map((element) => {
        this.listCart.push(element);
      });
    },
    btnCount(list, index, type) {
      let stock = 0;
      list.count = list.count ? list.count : 0;
      if (!localStorage.getItem(`${list.id}_stock`)) {
        localStorage.setItem(`${list.id}_stock`, list.stock);
      }

      if (!localStorage.getItem(`${list.id}_count`)) {
        localStorage.setItem(`${list.id}_count`, list.count.toString());
      }
      switch (type) {
        case "add":
          if (list.count < list.stock) {
            list.count = list.count + 1;
            list.stock = list.stock - 1;
          }
          break;
        case "remove":
          list.count = list.count - 1;
          list.count = list.count <= 0 ? 0 : list.count;
          stock =
            localStorage.getItem(`${list.id}_stock`) +
            localStorage.getItem(`${list.id}_count`);

          if (list.stock < stock) {
            list.stock = list.stock + 1;
          }
          break;
      }

      list.updateStock = list.stock;
      const cart = this.listCart;
      cart[index] = list;
      this.listCart = [...cart];
      this.calcTotal();
    },
    calcTotal() {
      this.total = 0;
      this.listCart.map((element) => {
        const unidad = element.count * element.price;
        this.total = this.total + unidad;
      });
    },
    totalDolar() {
      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(this.total);
      this.valueDolar = format;
      return format;
    },
    totalBsFormat() {
      const moneda = this.formatMoneda();
      let result = this.total * moneda;
      result = result.toFixed(2);
      const format = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(result);
      this.valueBs = format;
      return format;
    },
    formatMoneda() {
      let moneda = this.$store.state.setting.moneda;
      if (moneda) {
        const arrayMoneda = moneda.split(".");
        if (arrayMoneda.length > 2) {
          moneda = moneda.split(".").join("");
          moneda = moneda.replace(",", ".");
          moneda = parseFloat(moneda);
        } else if (moneda.indexOf(",") !== -1) {
          moneda = moneda.replace(",", ".");
          moneda = parseFloat(moneda);
        } else {
          moneda = parseFloat(moneda);
        }

        return moneda.toFixed(2);
      }
    },
    async getMarkStorage() {
      const { markAll } = MarkStorage();
      this.listMark = await markAll();
    },
    btnSelectMark() {
      this.sheet = true;
    },
    btnProduct(value) {
      this.sheet = false;
      this.modalProduct = true;
      this.dataMark = value;
      this.selectMark = value;
    },
    changeCart(event) {
      this.listCart = [];
      event.map((element) => {
        this.listCart.push(element);
      });
      this.calcTotal();
    },
    btnDeleteProduct() {
      const newCart = [];
      const cart = this.listCart;
      const index = cart.findIndex(
        (item) => item.id.indexOf(this.itemDelete.id) > -1
      );

      this.itemDelete.updateStock = this.itemDelete.count;
      this.$emit("onStock", {
        products: this.itemDelete,
      });

      delete cart[index];
      cart.map((item) => {
        if (item) {
          newCart.push(item);
        }
      });
      this.listCart = newCart;
      this.calcTotal();
      const data = {
        products: this.listCart,
        total: this.valueDolar,
        totalBs: this.valueBs,
      };
      this.clearProducts({ name: this.selectMark.name });
      this.$emit("onChange", data);
    },
    deleteProduct(list) {
      this.itemDelete = list;
      this.deleteModal = true;
    },
  },
};
</script>

<style lang="scss">
.cart-order {
  .eventNone {
    pointer-events: none;
    opacity: 0.7;
  }

  .container {
    padding: 2px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 110px auto;

    .img {
      width: 100%;
      display: flex;
      padding: 6px;

      img {
        border-radius: 5px;
        border: solid 1px #eee;
        object-fit: contain;
        object-position: center;
      }
    }

    .content {
      padding: 10px 8px 10px 12px;
      display: grid;
      align-items: flex-end;

      .close {
        position: absolute;
        top: 6px;
        right: 12px;

        i {
          font-size: 20px;
        }
      }

      .title {
        line-height: 21px;
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding-top: 5px;
        font-size: 15px !important;
        color: var(--color-second);
      }

      .grid-info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .price {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: var(--color-second);
        }

        .count {
          display: grid;
          grid-template-columns: 30px 40px 30px;

          .btn-minus {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              border-radius: 100%;
              background: var(--color-primary);
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            i {
              color: var(--color-white);
              font-size: 16px;
            }
          }

          .number {
            position: relative;
            display: flex;
            justify-content: center;

            input {
              border: solid 1px var(--color-second);
              width: 40px;
              border-radius: 6px;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              padding: 2px 8px;
            }
          }

          .btn-plus {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              border-radius: 100%;
              background: var(--color-primary);
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            i {
              color: var(--color-white);
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .footer {
    .grid-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 14px;
      font-weight: 500;

      .col2 {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        div {
          background: #eb445a;
          color: #fff;
          padding: 2px 10px;
          border-radius: 4px;
          margin-bottom: 5px;
          display: inline-block;
        }
      }
    }
  }

  .btn-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 11px;
  }
}

.contentMark {
  display: grid;
  grid-template-rows: 1fr 0.1fr 1fr;
  grid-template-areas: "area0" "bar" "area1";

  .area-0 {
    grid-area: area0;
  }

  .area-1 {
    grid-area: area1;
  }

  .bar {
    grid-area: bar;
    border-top: 1px solid #b8babb;
    margin: auto;
    width: 100%;
  }

  .logo {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 12rem;
    }
  }
}
</style>
