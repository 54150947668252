<template>
  <v-dialog
    v-model="viewModal"
    fullscreen
    scrollable
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="mobile-dialog detail-order"
  >
    <v-card>
      <v-card-title class="card-title">
        <CustomButtonAction :btnBack="true" @onChangeBack="btnBack()" />
        <span class="headline">Detalles</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="title-n" v-if="selectOrden.code">
          N° Pedido: {{ selectOrden.code }}
        </div>
        <div class="info-total">
          <div class="content-grid">
            <div class="row-grid">
              <div class="colum">Fecha:</div>
              <div class="colum">{{ selectOrden.createdAt | dateTime }}</div>
            </div>
            <div class="row-grid">
              <div class="colum">Cantidad de productos:</div>
              <div class="colum">{{ countProduct() }}</div>
            </div>
            <div class="row-grid">
              <div class="colum">Total:</div>
              <div class="colum">
                <div>{{ selectOrden.total }} $</div>
                <div>{{ selectOrden.totalBs }} Bs</div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-total" v-if="selectOrden.check === 1">
          <div class="content-grid">
            <div class="row-grid">
              <div class="colum">Cuotas:</div>
              <div class="colum">
                {{ selectOrden.weeks[selectOrden.weeks.length - 1].week }}
              </div>
            </div>
            <div class="row-grid">
              <div class="colum">Total acumulado:</div>
              <div class="colum">{{ getAccumulated(selectOrden.weeks) }} $</div>
            </div>
            <div class="row-grid">
              <div class="colum">Falta por cancelar:</div>
              <div class="colum">
                <div>{{ getTotalCancel(selectOrden.weeks) }} $</div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-option">
          <div class="content-grid">
            <div
              class="colum"
              @click="btnPayment()"
              v-if="selectOrden.check === 1"
            >
              <v-icon>mdi-cash-multiple</v-icon>
            </div>
            <div class="colum" @click="btnCart()">
              <v-icon>mdi-cart-variant</v-icon>
            </div>
            <div class="colum" @click="btnComment()">
              <v-icon>mdi-comment-text-outline</v-icon>
            </div>
            <div class="colum" @click="btnAdjunt()">
              <v-icon>mdi-image-outline</v-icon>
            </div>
            <div
              class="colum"
              v-if="selectOrden.check === 4"
              @click="btnPrintOrder()"
            >
              <v-icon v-if="!loaderDownPdf">mdi-arrow-collapse-down</v-icon>
              <v-progress-circular
                :size="30"
                color="second"
                indeterminate
                v-if="loaderDownPdf"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <CommentOrder
      :dialog.sync="modalComment"
      :btnLoader="btnCommentLoader"
      @onChange="addComment($event)"
    />

    <AdjuntOrder
      :dialog.sync="modalAdjunt"
      :btnLoader="btnAdjuntLoader"
      @onChange="addAdjunt($event)"
    />

    <CartOrder
      :dialog.sync="modalCart"
      :btnLoader="btnCartLoader"
      @onChange="addCart($event)"
      @onStock="updateStock($event)"
    />

    <PaymentOrder
      :dialog.sync="modalPayment"
      :btnLoader="btnPaymentLoader"
      @onChange="addPayment($event)"
    />

    <CustomPrintOrder :order="selectOrden" />
  </v-dialog>
</template>

<script>
import { jsPDF } from "jspdf";
import { mapState, mapActions } from "vuex";
import CommentOrder from "./comment.vue";
import AdjuntOrder from "./adjunt.vue";
import CartOrder from "./cart.vue";
import PaymentOrder from "./payment.vue";
import OrderServices from "../../../../core/services/order-services";
import ProductServices from "../../../../core/services/product-services";
import OrderStorage from "../../../../core/storage/order-storage";
import ProductStorage from "../../../../core/storage/product-storage";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomPrintOrder from "../../../../shared/components/custom-print-order/CustomPrintOrder.vue";

export default {
  name: "DetailOrder",
  data: () => ({
    viewModal: false,
    modalComment: false,
    modalAdjunt: false,
    modalCart: false,
    btnCommentLoader: false,
    btnAdjuntLoader: false,
    btnCartLoader: false,
    modalPayment: false,
    btnPaymentLoader: false,
    loaderDownPdf: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
  },
  computed: {
    ...mapState(["selectOrden"]),
  },
  components: {
    CustomButtonAction,
    CommentOrder,
    AdjuntOrder,
    CartOrder,
    PaymentOrder,
    CustomPrintOrder,
  },
  methods: {
    ...mapActions(["getListOrders", "setSelectOrdenItem", "getListProducts"]),
    btnBack() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
    },
    countProduct() {
      let count = 0;
      if (this.selectOrden && this.selectOrden.products) {
        this.selectOrden.products.map((item) => {
          count = count + item.count;
        });
        return count;
      }
    },
    btnComment() {
      this.modalComment = true;
    },
    btnAdjunt() {
      this.modalAdjunt = true;
    },
    btnCart() {
      this.modalCart = true;
    },
    btnPayment() {
      this.modalPayment = true;
    },
    btnPrintOrder() {
      this.loaderDownPdf = true;
      const div = document.getElementById("order-pdf");

      const doc = new jsPDF("p", "mm", "a4");
      doc.html(div, {
        callback: (print) => {
          print.save(`Pedido-${this.selectOrden.code}.pdf`);
          this.loaderDownPdf = false;
        },
        html2canvas: {
          scale: 0.235,
        },
      });
    },
    addComment(event) {
      const order = this.selectOrden;
      order.comment.push(event);
      this.btnCommentLoader = true;
      setTimeout(() => {
        const scroll = document.getElementById("comment");
        const height = scroll.scrollHeight;
        scroll.scrollTop = height;
      }, 100);
      this.updateOrderData(order);
    },
    addAdjunt(event) {
      const order = this.selectOrden;
      order.attached = event;
      this.btnAdjuntLoader = true;
      this.updateOrderData(order);
    },
    addCart(event) {
      const order = this.selectOrden;
      order.products = event.products;
      order.total = event.total;
      order.totalBs = event.totalBs;
      this.btnCartLoader = true;
      this.updateOrderData(order);
    },
    addPayment(event) {
      const order = this.selectOrden;
      if (event.check) {
        order.weeks = event.weeks;
        order.check = event.check;
        order.new = event.new;
      } else {
        order.weeks = event.weeks;
      }

      this.btnPaymentLoader = true;
      this.updateOrderData(order, event.modal);
    },
    updateOrderData(order, modal = false) {
      const { updateOrder } = OrderServices();
      const { orderAdd } = OrderStorage();
      updateOrder(order.id, order).then((resp) => {
        const response = resp.data;
        if (response.code === 201) {
          orderAdd(response.data);
          this.btnCommentLoader = false;
          this.btnAdjuntLoader = false;
          this.btnCartLoader = false;
          this.btnPaymentLoader = false;
          this.setSelectOrdenItem({ orden: response.data });
          this.getListOrders();

          if (modal) {
            this.modalPayment = false;
            this.$emit("onChange", true);
            this.btnBack();
            this.$toast.success("El pedido está en proceso");
          } else {
            this.$toast.success("La orden se ha actualizado");
          }
        }
      });
    },
    async updateStock(event) {
      const { productStokById } = ProductServices();
      const { productAdd } = ProductStorage();

      productStokById(event.products.id, event.products).then((resp) => {
        const response = resp.data;
        if (response.code === 201) {
          const data = {
            id: event.products.mark.id,
            name: event.products.mark.name,
            data: response.data,
            createdAt: new Date(),
          };
          productAdd(data);
          this.getListProducts();
        }
      });
    },
    getAccumulated(weeks) {
      let total = 0;
      weeks.map((item) => {
        total = total + parseFloat(item.payment);
      });
      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(total);
      return format;
    },
    getTotalCancel(weeks) {
      let total = 0;
      weeks.map((item) => {
        total = total + parseFloat(item.payment);
      });

      const result = this.selectOrden.total - total;
      const format = new Intl.NumberFormat("en-EN", {
        minimumFractionDigits: 2,
      }).format(result);
      return format;
    },
  },
};
</script>

<style lang="scss">
.detail-order {
  .v-card > .v-card__text {
    padding: 10px 16px 20px !important;
  }

  .title-n {
    font-size: 20px !important;
    word-break: normal;
    font-weight: 500;
    color: var(--color-second) !important;
    padding: 10px 0px 0px;
  }

  .info-total {
    margin-top: 20px;
    border: solid 1px #ccc;
    border-radius: 6px;

    .content-grid {
      padding: 10px;
      display: grid;
      row-gap: 10px;
      grid-template-columns: 1fr;

      .row-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .colum {
          font-size: 13px;
          color: var(--color-second);
          font-weight: 500;
        }

        .colum:nth-child(2) {
          text-align: right;
          font-size: 13px;
        }
      }
    }
  }

  .info-option {
    margin-top: 20px;

    .content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 20px;

      .colum {
        width: 100px;
        height: 100px;
        border: solid 1px #ccc;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        i {
          font-size: 3.2rem;
        }
      }
    }
  }
}
</style>
